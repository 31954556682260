<template>
  <b-modal
    id="modal-new-invoice"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    centered
    :title="text"
    size="xl"
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col cols="3">
          <validation-provider
            name="Project"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Project *"
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="form.projectDetail"
                label="name"
                :clearable="false"
                :options="projects"
                placeholder="Select Project"
                @input="fetchTerms(form.projectDetail)"
                :class="{ 'is-invalid': errors.length }"
              >
              <template #option="{ name, code, key }">
                <div class="d-flex justify-content-between">
                  <span>{{ code }} - {{ key }}- {{ name }}</span>
              </div>
              </template>
              </custom-select>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Client"
          >
            {{ form.client ? form.client : client }}
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <validation-provider
            name="Reminder Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Reminder Date"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="form.reminderDate"
                :class="{ 'is-invalid': errors.length }"
                disabled
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div class="my-2">Invoice Code</div>
      <b-row>
        <b-col>
          <b-form-input
            v-model="form.invoiceNo"
            disabled
          />
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="form.isDifferentAddress"
            :value="true"
            :unchecked-value="false"
            @change="checkBillingAddress"
          >
            Different Billing Address
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Invoice Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Invoice Date *"
              :invalid-feedback="errors[0]"
            >
              <b-form-datepicker
                v-model="form.invoiceDate"
                :class="{ 'is-invalid': errors.length }"
                @input="getReminderDate"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Client Name"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              :label="!form.isDifferentAddress ? 'Billing Address' : 'Client Name' "
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                v-show="form.isDifferentAddress"
                v-model="form.companyName"
                :disabled="!form.isDifferentAddress"
              />
              <b-form-textarea
                v-show="!form.isDifferentAddress"
                v-model="form.companyName"
                rows="4"
                max-rows="6"
                disabled
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <div class="my-2">Overdue *</div>
      <b-row>
        <b-col cols="3">
          <validation-provider
            name="Overdue"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                v-model="form.overdueLength"
                type="number"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <validation-provider
            name="Overdue Unit"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              :invalid-feedback="errors[0]"
            >
              <custom-select
                v-model="form.overdueUnit"
                :clearable="false"
                :options="units"
                placeholder="Select Unit"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group
            v-if="form.isDifferentAddress"
            label="Billing Address *"
          >
            <b-form-textarea
              v-model="form.billingAddress"
              rows="4"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
          <b-col>
          <b-form-checkbox
            v-model="form.isPaymentTerm"
            :value="true"
            :unchecked-value="false"
          >
            Build with payment term
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
            <b-form-group
            v-if="form.isPaymentTerm"
              label="Terms"
            >
              <custom-select
                v-model="form.paymentTermId"
                label="title"
                :clearable="false"
                :options="isEdit ? terms : termList"
                :reduce="({ id }) => id"
                placeholder="Select terms"
              />
            </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label="Additional Note"
      >
        <vue-editor
          v-model="form.additionalNote"
          :editor-toolbar="customToolbar"
        />
      </b-form-group>
      <div class="d-flex align-items-center justify-content-between">
        <h6 class="font-weight-bold">Invoice Detail</h6>
        <b-button
          class="text-white px-5"
          variant="warning"
          @click="openModal(null)"
        >
          Add
        </b-button>
      </div>
      <b-table
        class="mt-2"
        striped
        hover
        :items="form.invoiceDetails"
        :fields="fields"
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(price)="{ item }">
          {{ formatMoney(item.price, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
        <template #cell(discount)="{ item }">
          {{ formatMoney(item.discount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
        <template #cell(amount)="{ item }">
          {{ formatMoney( (item.price * item.quantity ), { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
        <template #cell(action)="{ item, index }">
          <div class="data">
            <b-link
              class="text-secondary mx-2"
              @click="openModal(item)"
            >
              <fa-icon icon="edit" />
            </b-link>
            <b-link
              class="text-secondary mx-2"
              @click="removeInvoice(index)"
            >
              <fa-icon icon="trash-alt" />
            </b-link>
          </div>
        </template>
      </b-table>
      <b-row class="mt-2 d-flex justify-content-end">
         <b-col cols="2">Sub Total</b-col>
        <b-col cols="3">
          {{ formatMoney(form.subtotal, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
          </b-col>
      </b-row>
      <b-row class="mt-2 d-flex justify-content-end">
         <b-col cols="2">Discount</b-col>
        <b-col cols="3">
          {{ formatMoney(form.discountTotal, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </b-col>
    </b-row>
        <b-row class="mt-2 d-flex justify-content-end">
          <b-col cols="2">
            <b-form-checkbox
            v-model="form.isAdditionalDiscount"
            :value="true"
            :unchecked-value="false"
            @change="changeAdditionalDiscount"
          >
            Additional Discount
          </b-form-checkbox></b-col>
          <b-col cols="3">
          <b-input-group
                prepend="Rp"
              >
                <money
                  class="form-control"
                  v-model="form.additionalDiscount"
                  placeholder="0"
                  :disabled="!form.isAdditionalDiscount"
                />
              </b-input-group></b-col>
        </b-row>
       <b-row class="mt-2 d-flex justify-content-end">
        <b-col cols="1">
          <b-form-checkbox
            v-model="form.isPpn"
            :value="true"
            :unchecked-value="false"
            @change="changePpn"
          >
            PPN
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
             <b-form-input
                placeholder="0"
                v-model="form.ppnTaxRate"
                type="number"
                max="100"
                min="0"
                @change="calculatePpn"
                :disabled="!form.isPpn"
              />
          </b-col>
        <b-col cols="3">{{ formatMoney(ppnTaxAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</b-col>
       </b-row>
      <b-row class="mt-2 d-flex justify-content-end">
         <b-col cols="1">
          <b-form-checkbox
            v-model="form.isPph"
            :value="true"
            :unchecked-value="false"
            @change="changePph"
          >
            PPh 23
          </b-form-checkbox></b-col>
            <b-col cols="1">
             <b-form-input
                placeholder="0"
                v-model="form.pphTaxRate"
                type="number"
                max="100"
                min="0"
                @change="calculatePpn"
                :disabled="!form.isPph"
              />
          </b-col>
        <b-col cols="3">
          {{ formatMoney(pphTaxAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </b-col>
      </b-row>
      <b-row class="mt-2 d-flex justify-content-end font-weight-bold">
         <b-col cols="2">TOTAL</b-col>
        <b-col cols="3" class="font-weight-bold">{{ formatMoney(total, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}</b-col>
      </b-row>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-white"
        variant="warning"
        block
        @click="submit"
      >
        Save
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
    </div>
  <modal-invoice-detail
  :add-invoice-form="form.invoiceDetail"
  :invoiceForm="invoiceForm"
  @close="this.$options.data().invoiceForm"
  @set="addInvoiceDetail" />
  </b-modal>
</template>

<script>
import { formatMoney } from 'accounting-js'
import api from '@/api'
import modalInvoiceDetail from './ModalInvoiceDetail.vue'
import moment from 'moment'

export default {
  components: {
    modalInvoiceDetail
  },
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    text: {
      type: String,
      default: () => ''
    },
    invoiceId: {
      type: String,
      default: () => ''
    },
    terms: {
      type: Array,
      default: () => []
    },
    taxes: {
      type: Array,
      default: () => []
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    this.fetchProject()
  },

  data: () => ({
    isBusy: false,
    labelClient: 'Billing Address',
    client: null,
    isPpn: false,
    isPph: false,
    isUpdate: false,
    termList: [],
    companyName: '',
    additionalDiscount: 0,
    totalAmount: 0,
    pphTax: 0,
    ppnTax: 0,
    invoiceForm: {
      description: null,
      quantity: 0,
      price: 0,
      amount: 0,
      discount: 0,
      subTotal: 0,
      isEdit: false
    },
    addInvoiceForm: [
      {
        description: null,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        subTotal: 0,
        isEdit: false
      }
    ],
    sizeChangedBy: null,
    addresses: [],
    options: [],
    projects: [],
    units: [
      'Day(s)',
      'Month(s)',
      'Year(s)'
    ],
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'formula'],
      [{ direction: 'rtl' }],
      ['clean']
    ],
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'description', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'quantity', label: 'qty', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'price', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'discount', label: 'disc', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ]
  }),

  computed: {
    total() {
      return this.totalAmount === 0 ? this.form.subtotal - this.form.discountTotal - this.form.additionalDiscount + this.pphTaxAmount + this.ppnTaxAmount : this.totalAmount
    },
    totalNoDisc() {
      return this.form.subtotal - this.form.discountTotal - this.form.additionalDiscount
    },
    pphTaxAmount() {
      return this.form.pphTaxRate * this.totalNoDisc / 100
    },
    ppnTaxAmount() {
      return this.form.ppnTaxRate * this.totalNoDisc / 100
    }
  },

  methods: {
    formatMoney,
    getReminderDate() {
      this.form.reminderDate = new Date(moment(this.form.invoiceDate).subtract(3, 'd').format('YYYY-MM-DD'))
    },
    changeAdditionalDiscount() {
      if (!this.form.isAdditionalDiscount) this.form.additionalDiscount = 0
    },
    calculatePpn() {
      if (this.form.subtotal > 0) {
        this.form.pphTaxAmount = this.pphTaxAmount !== 0 ? this.pphTaxAmount : this.form.pphTaxRate * this.form.subtotal / 100
        this.ppnTax = this.ppnTaxAmount !== 0 ? this.ppnTaxAmount : this.form.ppnTaxRate * this.form.subtotal / 100
      }
    },
    calculateTotal(data) {
      this.form.subtotal = 0
      this.form.discountTotal = 0
      for (var i in data) {
        this.form.subtotal += data[i].price * data[i].quantity
        this.form.discountTotal += data[i].discount
      }
    },
    removeInvoice(index) {
      this.form.invoiceDetails.splice(index, 1)
      this.calculateTotal(this.form.invoiceDetails)
    },
    async submit() {
      try {
        var ppn = this.taxes.find(e => e.code === 'T')
        var pph = this.taxes.find(e => e.code === 'P')
        var taxDetail = []
        var pphTax = {
          taxId: pph.id,
          rate: this.form.pphTaxRate,
          amount: this.pphTaxAmount
        }
        if (this.form.pphTaxRate > 0) taxDetail.push(pphTax)
        var ppnTax = {
          taxId: ppn.id,
          rate: this.form.ppnTaxRate,
          amount: this.ppnTaxAmount
        }
        if (this.form.ppnTaxRate > 0) taxDetail.push(ppnTax)
        var form = {
          id: this.form.id,
          projectId: this.form.projectDetail.id,
          invoiceDate: this.form.invoiceDate,
          isDifferentAddress: this.form.isDifferentAddress,
          companyName: this.form.isDifferentAddress ? this.form.companyName : this.client,
          billingAddress: this.form.isDifferentAddress ? this.form.billingAddress : this.form.companyName,
          overdueLength: this.form.overdueLength,
          overdueUnit: this.form.overdueUnit,
          reminderDate: this.form.reminderDate,
          isAdditionalDiscount: this.form.isAdditionalDiscount,
          additionalDiscount: this.form.additionalDiscount,
          additionalNote: this.form.additionalNote,
          status: this.form.status,
          invoiceDetail: this.form.invoiceDetails,
          taxDetail: taxDetail,
          subtotal: this.form.subtotal,
          discountTotal: this.form.discountTotal,
          paymentTermId: this.form.paymentTermId
        }
        this.form.companyName = form.companyName
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return false
        }
        this.isBusy = true
        await api.invoice.save(form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(`Success ${this.isUpdate ? 'update' : 'save'} Invoice`, {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$bvModal.hide('modal-new-invoice')
        setTimeout(() => {
        }, 2000)
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
      this.$emit('refresh')
    },
    addInvoiceDetail(item) {
      if (!item.isEdit) {
        this.form.invoiceDetails.push(item)
      }
      this.calculateTotal(this.form.invoiceDetails)
    },
    checkBillingAddress() {
      this.labelClient = this.form.isDifferentAddress ? 'Client Name *' : 'Billing Address *'
    },
    onkeydown(e) {
      this.sizeChangedBy = e.key ? 'input' : 'list'
    },
    getAddress() {
      this.fetchAddress()
    },
    cancel() {
      this.$bvModal.hide('modal-new-invoice')
    },
    openModal(item) {
      this.invoiceForm = item || this.$options.data().invoiceForm
      this.invoiceForm.isEdit = item !== null
      this.$bvModal.show('modal-invoice-detail')
    },
    async fetchProject() {
      const { data } = await api.project.listProject()
      this.projects = data.data
    },
    async fetchAddress() {
      const { data } = await api.client.list()
      this.addresses = data.data
    },
    async fetchTerms(form) {
      const { data } = await api.paymentTerm.get(form.id)
      this.termList = data.data.length !== 0 ? data.data.paymentTerms.filter(v => v.invoiceId === null) : []
      this.form.paymentTermId = this.isUpdate ? null : this.form.paymentTermId
      this.client = form.clientName || '-'
      this.form.companyName = `${form.clientName} - ${form.address}`
    },
    changePpn() {
      if (!this.form.isPpn) this.form.ppnTaxRate = 0
    },
    changePph() {
      if (!this.form.isPph) this.form.pphTaxRate = 0
    }
  }
}
</script>
