<template>
  <b-modal
    id="modal-delete-invoice"
    hide-header
    hide-footer
    centered
  >
    <div class="text-center">
      <img src="@/assets/close_up.png" />
    </div>
    <h4 class="text-danger font-weight-bold text-center">Delete Invoice {{ invoiceNo }}</h4>
    <div class="text-center">Are you sure to delete invoice ?</div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-warning"
        variant="outline-warning"
      @click="onClickBack">
        No, Cancel
      </b-button>
      <b-button
        class="next ml-3"
        variant="warning"
      @click="remove">
        Delete
      </b-button>
    </div>
  </b-modal>
</template>
<script>

export default {
  props: {
    invoiceNo: {
      types: String,
      default: () => ''
    },
    id: {
      types: String,
      default: () => ''
    }
  },
  methods: {
    onClickBack() {
      this.$bvModal.hide('modal-delete-invoice')
    },
    remove() {
      this.$bvModal.hide('modal-delete-invoice')
      this.$emit('click', this.id)
    }
  }
}
</script>
