<template>
  <b-modal
    id="modal-sent-invoice"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="mb-4 d-flex justify-content-between">
      <h5 class="font-weight-bold">Sent Invoice</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <validation-observer
      ref="form"
      slim
    >
      <validation-provider
        name="Sent Date"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Sent Date *"
          :invalid-feedback="errors[0]"
        >
          <b-form-datepicker
            v-model="form.sendDate"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Remark"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Remark"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-model="form.remark"
            placeholder="Enter remark"
            rows="4"
            max-rows="6"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-white"
        variant="warning"
        block
        @click="submit"
      >
        Save
      <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    invoiceId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    isBusy: false,
    form: {
      sendDate: null,
      remark: null
    }
  }),
  methods: {
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      this.isBusy = true
      try {
        this.form.invoiceId = this.invoiceId
        await api.invoice.send(this.form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success Sent Invoice', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$bvModal.hide('modal-sent-invoice')
        setTimeout(() => {
        }, 2000)
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }

      this.$emit('submit', this.form)
      this.$emit('refresh')
    },
    resetModal() {
    },
    cancel() {
      this.$bvModal.hide('modal-sent-invoice')
    }
  }
}
</script>
