var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h6',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Filter")]),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Client Name"}},[_c('custom-select',{attrs:{"label":"code","multiple":"","placeholder":"Select client name","options":_vm.clientList,"reduce":function (ref) {
	var code = ref.code;

	return code;
}},model:{value:(_vm.clientCode),callback:function ($$v) {_vm.clientCode=$$v},expression:"clientCode"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Status Invoice"}},[_c('custom-select',{attrs:{"label":"name","multiple":"","placeholder":"Select status invoice","options":_vm.statusOption,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Period Invoice"}},[_c('date-picker',{attrs:{"placeholder":"Select periode","range":""},model:{value:(_vm.periodInvoice),callback:function ($$v) {_vm.periodInvoice=$$v},expression:"periodInvoice"}})],1)],1)],1),_c('div',{staticClass:"button-project mt-5 d-flex justify-content-end"},[_c('b-button',{staticClass:"reset mr-3",attrs:{"variant":"outline-warning"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('b-button',{staticClass:"next",attrs:{"variant":"warning"},on:{"click":_vm.changeFilter}},[_vm._v(" Apply Filter ")])],1)],1),_c('b-card',{staticClass:"mt-3",attrs:{"body-class":"py-4 px-3"}},[_c('div',{staticClass:"mt-3 d-flex justify-content-between"},[_c('b-button',{staticClass:"next",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openModal(null)}}},[_vm._v(" Add New Invoice ")])],1),_c('br'),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.items.length),expression:"!items.length"}]},[_c('b-col',[_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("@/assets/icon-no-invoice.png")}})])])],1),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length),expression:"items.length"}],ref:"table",attrs:{"items":_vm.items,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"striped":"","responsive":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"cell(no)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(action)",fn:function(row){return [_c('b-link',{on:{"click":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}},[(!row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/add-toggle.svg")}}):_vm._e(),(row.detailsShowing)?_c('img',{attrs:{"src":require("@/assets/collaps-toggle.svg")}}):_vm._e()])]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"items":row.item.invoices,"fields":_vm.detailFields,"striped":"","responsive":"sm"},scopedSlots:_vm._u([{key:"cell(no)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(invoiceDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoiceDate ? _vm.moment(item.invoiceDate).format('DD MMM YYYY') : '-')+" ")]}},{key:"cell(overdueDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.overdueDate ? _vm.moment(item.overdueDate).format('DD MMM YYYY') : '-')+" ")]}},{key:"cell(paymentDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentDate ? _vm.moment(item.paymentDate).format('DD MMM YYYY') : '-')+" ")]}},{key:"cell(totalPayment)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.totalPayment, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(submitDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.submitDate ? _vm.moment(item.submitDate).format('DD MMM YYYY') : '-')+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{class:_vm.changeStatus(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[(item.status == 'UNPAID' || item.status == 'DRAFT')?_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.fetchInvoiceDetail(item.id, false)}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1):_vm._e(),_c('b-link',{staticClass:"text-secondary mx-2"},[_c('fa-icon',{attrs:{"icon":"eye"},on:{"click":function($event){return _vm.openInvoiceDetail(item)}}})],1),(item.status == 'DRAFT')?_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.removeInvoice(item)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()],1)]}}],null,true)})]}},{key:"cell(projectAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(amountPaid)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.amountPaid, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(amountUnpaid)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.amountUnpaid, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}},{key:"cell(amountOverdue)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.amountOverdue, { symbol: 'Rp.', precision: 0, thousand: '.' }))+" ")]}}])}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('span',[_vm._v("Show")]),_c('b-form-select',{staticClass:"page-option ml-2 mr-2 w-auto",attrs:{"options":_vm.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('span',[_vm._v("Entries")])],1),_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.totalRows,"pills":"","hide-goto-end-buttons":""},on:{"input":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('span',{staticClass:"show-entries mr-auto"},[_vm._v(" "+_vm._s(("Show " + (_vm.totalRows === 0 ? 0 : ((_vm.currentPage-1) * _vm.perPage) + 1) + " to " + (_vm.totalRows > ((_vm.currentPage) * _vm.perPage) ? ((_vm.currentPage) * _vm.perPage) : _vm.totalRows) + " of " + _vm.totalRows + " entries"))+" ")])],1)])],1),_c('modal-new-invoice',{attrs:{"text":_vm.text,"form":_vm.detail,"terms":_vm.terms,"invoiceId":_vm.invoiceId,"taxes":_vm.taxes,"isEdit":_vm.isEdit},on:{"close":function($event){this.$options.data().detail},"refresh":_vm.fetchInvoices}}),_c('modal-invoice-detail'),_c('modal-delete-invoice',{attrs:{"id":_vm.id,"invoiceNo":_vm.invoiceNo},on:{"click":_vm.deleteInvoice}}),_c('modal-view-invoice',{attrs:{"detail":_vm.detail},on:{"refresh":_vm.fetchInvoices}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }