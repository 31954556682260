<template>
 <div>
  <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row>
        <b-col>
          <b-form-group
            label="Client Name"
          >
            <custom-select
              v-model="clientCode"
              label="code"
              multiple
              placeholder="Select client name"
              :options="clientList"
              :reduce="({ code }) => code"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Status Invoice"
          >
            <custom-select
              v-model="status"
              label="name"
              multiple
              placeholder="Select status invoice"
              :options="statusOption"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
        <b-form-group
            label="Period Invoice"
          >
        <date-picker placeholder="Select periode" v-model="periodInvoice" range></date-picker>
        </b-form-group>
        </b-col>
      </b-row>
      <div class="button-project mt-5 d-flex justify-content-end">
        <b-button
          class="reset mr-3"
          variant="outline-warning"
          @click="resetFilter"
        >
          Reset Filter
        </b-button>
        <b-button
          class="next"
          variant="warning"
          @click="changeFilter"
        >
          Apply Filter
        </b-button>
        </div>
    </b-card>
    <b-card class="mt-3" body-class="py-4 px-3">
    <div class="mt-3 d-flex justify-content-between">
      <b-button
        class="next"
        variant="warning"
        @click="openModal(null)"
      >
        Add New Invoice
      </b-button>
      </div>
      <br/>
    <b-row v-show="!items.length">
      <b-col>
       <div align="center">
       <img src="@/assets/icon-no-invoice.png"/>
      </div>
      </b-col>
    </b-row>
    <b-table
    ref="table"
    v-show="items.length"
    :items="items"
    :fields="fields"
    :per-page="perPage"
    :current-page="currentPage"
    :sort-by.sync="sortBy"
    striped
    responsive>
        <template #cell(no)="{ item, index }">
          {{ index + 1 }}
        </template>

        <template #cell(action)="row">
         <b-link
            v-model="row.detailsShowing" @click="row.toggleDetails"
          >
            <img v-if="!row.detailsShowing" src="@/assets/add-toggle.svg"/>
            <img v-if="row.detailsShowing" src="@/assets/collaps-toggle.svg"/>
          </b-link>
      </template>

      <template #row-details="row">
        <b-table :items="row.item.invoices" :fields="detailFields" striped responsive="sm">
          <template #cell(no)="{ item, index }">
            {{ index + 1 }}
          </template>

          <template #cell(invoiceDate)="{ item }">
          {{ item.invoiceDate ? moment(item.invoiceDate).format('DD MMM YYYY') : '-' }}
          </template>

          <template #cell(overdueDate)="{ item }">
          {{ item.overdueDate ? moment(item.overdueDate).format('DD MMM YYYY') : '-' }}
         </template>

         <template #cell(paymentDate)="{ item }">
         {{ item.paymentDate ? moment(item.paymentDate).format('DD MMM YYYY') : '-' }}
         </template>

         <template #cell(totalPayment)="{ item }">
        {{ formatMoney(item.totalPayment, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>

          <template #cell(submitDate)="{ item }">
          {{ item.submitDate ? moment(item.submitDate).format('DD MMM YYYY') : '-' }}
          </template>

        <template #cell(status)="{ item }">
        <b-badge
          :class="changeStatus(item.status)"
        >
          {{ item.status }}
        </b-badge>
        </template>

<template #cell(action)="{ item }">
        <div class="data">
          <b-link v-if="item.status == 'UNPAID' || item.status == 'DRAFT'"
            class="text-secondary mx-2"
            @click="fetchInvoiceDetail(item.id, false)"
          >
            <fa-icon icon="edit" />
          </b-link>
           <b-link
            class="text-secondary mx-2"
          >
            <fa-icon icon="eye" @click="openInvoiceDetail(item)"/>
          </b-link>
          <b-link
          v-if="item.status == 'DRAFT'"
            class="text-secondary mx-2"
            @click="removeInvoice(item)"
          >
            <fa-icon icon="trash-alt"
                />
          </b-link>
        </div>
       </template>
        </b-table>
      </template>
       <template #cell(projectAmount)="{ item }">
        {{ formatMoney(item.projectAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(amountPaid)="{ item }">
        {{ formatMoney(item.amountPaid, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(amountUnpaid)="{ item }">
        {{ formatMoney(item.amountUnpaid, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
      <template #cell(amountOverdue)="{ item }">
        {{ formatMoney(item.amountOverdue, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
      </template>
    </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
          </span >
        </div>
      </div>
      </b-card>
    <modal-new-invoice
    :text="text"
    :form="detail"
    :terms="terms"
    :invoiceId="invoiceId"
    :taxes="taxes"
    :isEdit="isEdit"
    @close="this.$options.data().detail"
    @refresh="fetchInvoices"/>
    <modal-invoice-detail />
    <modal-delete-invoice
    @click="deleteInvoice"
    :id="id"
    :invoiceNo="invoiceNo"/>
    <modal-view-invoice :detail="detail" @refresh="fetchInvoices"/>
</div>
</template>

<script>
import api from '@/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import modalNewInvoice from './ModalNewInvoice.vue'
import modalInvoiceDetail from './ModalInvoiceDetail.vue'
import modalDeleteInvoice from '@/components/ModalDeleteInvoice.vue'
import modalViewInvoice from './ModalViewInvoice.vue'
import { formatMoney } from 'accounting-js'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    modalNewInvoice,
    modalInvoiceDetail,
    modalDeleteInvoice,
    modalViewInvoice
  },

  props: {
  },

  created() {
    this.fetchClientList()
    this.fetchStatus()
    this.fetchInvoices()
    this.fetchTax()
  },

  data: () => ({
    id: null,
    text: '',
    isEdit: false,
    invoiceId: null,
    invoiceNo: null,
    terms: [],
    detail: {
      id: null,
      client: null,
      invoiceNo: null,
      projectId: null,
      companyName: null,
      projectDetail: {
        client: {
          code: null,
          name: null,
          address: null,
          country: null,
          state: null,
          city: null,
          district: null,
          zipCode: null
        },
        code: null,
        name: null,
        overdueLength: 0,
        overdueUnit: null,
        paymentTerms: [
          {
            title: null
          }
        ]
      },
      paymentTermId: null,
      paymentTerm: {
        title: null
      },
      invoiceDate: null,
      additionalNote: null,
      overdueLength: 0,
      overdueUnit: null,
      overdueDate: null,
      reminderDate: null,
      subtotal: 0,
      total: 0,
      discountTotal: 0,
      isAdditionalDiscount: false,
      isDifferentAddress: false,
      isPaymentTerm: false,
      additionalDiscount: 0,
      discount: 0,
      totalPayment: 0,
      status: null,
      sendDate: null,
      isPpn: false,
      isPph: false,
      ppnTaxRate: 0,
      pphTaxRate: 0,
      ppnTaxAmount: 0,
      pphTaxAmount: 0,
      remarks: null,
      filename: null,
      invoiceDetails: [],
      invoiceDetail: [],
      invoiceDetailTaxes: [],
      invoicePayments: [],
      invoiceFileHistories: []
    },
    taxes: [],
    invoiceList: [],
    sortBy: 'projectName',
    search: '',
    valueOptions: [],
    filters: '',
    busy: false,
    periodInvoice: [],
    clientList: [],
    statusOption: [],
    status: [],
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'clientCode', label: 'Client Code', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectName', label: 'Project Name', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectKey', label: 'Project Key', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectAmount', label: 'Project Amount', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'qtyPaid', label: 'Qty Paid', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'amountPaid', label: 'Amount Paid', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'qtyUnpaid', label: 'Qty Unpaid', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'amountUnpaid', label: 'Amount Unpaid', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'qtyOverdue', label: 'Qty Overdue', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'amountOverdue', label: 'Amount Overdue', tdClass: 'text-center', thClass: 'text-center', sortable: true }
    ],
    items: [],
    clientCode: [],
    details: [],
    detailFields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceNo', label: 'Invoice No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'invoiceDate', label: 'Invoice Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'totalPayment', label: 'Payment Amount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'status', label: 'Status', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'overdueDate', label: 'Due Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'submitDate', label: 'Submit Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'paymentDate', label: 'Paid Date', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    pageOptions: [10, 20, 50, 100]
  }),
  methods: {
    formatMoney,
    changeStatus(status) {
      if (status === 'PAID') {
        return 'paid px-3 py-2 ml-3'
      } else if (status === 'UNPAID') {
        return 'unpaid px-3 py-2 ml-3'
      } else {
        return 'draft px-3 py-2 ml-3'
      }
    },
    removeInvoice(item) {
      this.id = item.id
      this.invoiceNo = item.invoiceNo
      this.$bvModal.show('modal-delete-invoice')
    },
    async deleteInvoice() {
      try {
        this.isBusy = true
        await api.invoice.deleteInvoice(this.id)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success delete Invoice', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.fetchInvoices()
        setTimeout(() => {
          this.$bvModal.hide('modal-delete-invoice')
        }, 2000)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    changeFilter() {
      if (this.clientCode.length > 0) {
        var clientCode = ''
        this.clientCode.map(v => {
          clientCode += v + '|'
        })
        clientCode = clientCode.substring(0, clientCode.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'clientCode@=' + clientCode
      }
      if (this.status.length > 0) {
        var status = ''
        this.status.map(v => {
          status += v + '|'
        })
        status = status.substring(0, status.length - 1)
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'status@=' + status
      }
      if (this.periodInvoice.length > 0) {
        this.filters = this.filters !== '' ? this.filters + ',' : ''
        this.filters += 'periodeInvoice==' + moment(this.periodInvoice[0]).format('YYYY-MM-DD') + ';' + moment(this.periodInvoice[1]).format('YYYY-MM-DD')
      }
      this.fetchInvoices()
      this.filters = ''
    },
    resetFilter() {
      this.clientCode = []
      this.status = []
      this.periodInvoice = []
      this.filters = ''
      this.currentPage = 1
      this.fetchInvoices()
    },
    async fetchInvoices() {
      const { data } = await api.invoice.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.filters,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data
    },
    async fetchStatus() {
      const { data } = await api.invoice.status()
      this.statusOption = data.data
    },
    async fetchTerms(projectId) {
      const { data } = await api.paymentTerm.get(projectId)
      this.terms = data.data.length !== 0 ? data.data.paymentTerms : []
    },
    openModal(item) {
      this.detail = item || this.$options.data().detail
      if (item) {
        this.text = 'Edit Invoice'
        this.invoiceId = item.id
        this.isEdit = true
        this.fetchTerms(item.projectId)
      } else {
        this.text = 'Add New Invoice'
        this.invoiceId = null
        this.isEdit = false
        this.detail.paymentTerm = false
      }
      this.$bvModal.show('modal-new-invoice')
    },
    openInvoiceDetail(item) {
      this.fetchInvoiceDetail(item.id, true)
      this.$bvModal.show('modal-view-invoice')
    },
    async fetchInvoiceDetail(id, isView) {
      const { data } = await api.invoice.detail(id)
      this.detail = {
        ...this.detail,
        ...data
      }
      this.detail.isPaymentTerm = data.paymentTermId !== null
      this.detail.isBillingAddress = data.billingAddress !== null
      this.detail.isDifferentAddress = data.companyName !== null || false
      this.detail.billingAddress = this.detail.isDifferentAddress ? data.billingAddress : data.projectDetail.client ? data.projectDetail.client.address : '-'
      this.detail.companyName = this.detail.isDifferentAddress ? data.companyName : data.projectDetail.client ? `${data.projectDetail.client.name} - ${data.projectDetail.client.address}` : '-'
      this.detail.client = data.projectDetail.client ? data.projectDetail.client.name : '-'
      var ppn = this.taxes.find(e => e.code === 'T')
      var pph = this.taxes.find(e => e.code === 'P')
      if (ppn) {
        var ppnTax = this.detail.invoiceDetailTaxes.find(x => x.taxId === ppn.id)
        if (ppnTax) this.detail.isPpn = true
        this.detail.ppnTaxRate = ppnTax ? ppnTax.taxRate : 0
        this.detail.ppnTaxAmount = ppnTax ? ppnTax.taxAmount : 0
      }
      if (pph) {
        var pphTax = this.detail.invoiceDetailTaxes.find(x => x.taxId === pph.id)
        if (pphTax) this.detail.isPph = true
        this.detail.pphTaxRate = pphTax ? pphTax.taxRate : 0
        this.detail.pphTaxAmount = pphTax ? pphTax.taxAmount : 0
      }
      this.calculateTotal(this.detail)
      if (this.detail.invoiceDetails.length > 0) {
        this.detail.invoiceDetails.map((v, i) => {
          if (i % 2 === 0) {
            v._rowVariant = 'warning'
          }
        })
      }
      if (!isView) this.openModal(this.detail)
    },
    async fetchTax() {
      const { data } = await api.tax.list()
      this.taxes = data
    },
    calculateTotal(data) {
      this.detail.subtotal = 0
      this.detail.discount = 0
      this.detail.total = 0
      var invoiceDetails = data.invoiceDetails
      if (invoiceDetails.length > 0) {
        for (var i in invoiceDetails) {
          this.detail.subtotal += invoiceDetails[i].price * invoiceDetails[i].quantity
          this.detail.discount += invoiceDetails[i].discount
          this.detail.total = this.detail.subtotal - this.detail.additionalDiscount - this.detail.discount + this.detail.ppnTaxAmount + this.detail.pphTaxAmount
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.draft {
  width: 53px;
  height: 22px;

  /* Gray 1 */

  background: #A4A4A4;
  border-radius: 4px;
}
.paid {
  width: 49px;
  height: 22px;

  /* Green 2 */

  background: #4CAF50;
  border-radius: 4px;
}
.unpaid {
  width: 64px;
  height: 22px;
  /* Main Color Darker */

  background: #F7AC26;
  border-radius: 4px;
}
.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
