<template>
  <b-modal
    id="modal-invoice-detail"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="mb-2 d-flex justify-content-between">
      <h5 class="font-weight-bold">Add Invoice Detail</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <validation-observer
      ref="invoiceForm"
      slim
    >
      <validation-provider
        name="Description"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Description *"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-model="invoiceForm.description"
            placeholder="Enter your description"
            rows="4"
            max-rows="6"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <b-row>
        <b-col cols="3">
          <validation-provider
            name="Qty"
            rules="min_value:1|max_value:1000"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Qty *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="0"
                v-model="invoiceForm.quantity"
                type="number"
                max="100"
                min="0"
                @input="calculateAmount"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Price"
            rules="required|min_value:0"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Price *"
              :invalid-feedback="errors[0]"
            >
              <b-input-group
                prepend="Rp"
              >
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="invoiceForm.price"
                  @input="calculateAmount"
                  placeholder="0"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-0">
        <b-col cols="3">
          Amount
        </b-col>
        <b-col>
          {{ formatMoney(amount ? amount : invoiceForm.amount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <b-form-group
              label="Discount"
            >
              <b-input-group
                prepend="Rp"
              >
                <money
                  class="form-control"
                  v-model="invoiceForm.discount"
                  placeholder="0"
                />
              </b-input-group>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-0">
        <b-col cols="3">
          Sub Total
        </b-col>
        <b-col>
          {{ formatMoney(subtotal ? subtotal : invoiceForm.subtotal, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </b-col>
      </b-row>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-white"
        variant="warning"
        block
        @click="submit"
      >
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { formatMoney } from 'accounting-js'

export default {
  props: {
    invoiceForm: {
      type: Object,
      default: () => ({
        description: null,
        quantity: 0,
        price: 0,
        amount: 0,
        discount: 0,
        subTotal: 0
      })
    }
  },
  data: () => ({
    description: null,
    quantity: 0,
    price: 0,
    discount: 0,
    amount: 0,
    subtotal: 0
  }),

  methods: {
    formatMoney,
    async submit() {
      const valid = await this.$refs.invoiceForm.validate()
      if (!valid) {
        return false
      }
      var form = {
        description: this.invoiceForm.description,
        quantity: this.invoiceForm.quantity,
        price: this.invoiceForm.price,
        discount: this.invoiceForm.discount,
        isEdit: this.invoiceForm.isEdit
      }
      this.$emit('set', form)
      this.$bvModal.hide('modal-invoice-detail')
    },
    calculateAmount() {
      var price = this.invoiceForm.price || this.price
      var qty = this.invoiceForm.quantity || this.quantity
      this.amount = qty * price
      this.subtotal = this.amount - (this.invoiceForm.discount || this.discount)
    },
    resetModal() {
    },
    cancel() {
      this.$bvModal.hide('modal-invoice-detail')
    }
  }
}
</script>
