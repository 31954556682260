var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-payment-confirmation","hide-header":"","hide-footer":"","centered":""},on:{"hidden":_vm.resetModal}},[_c('div',{staticClass:"mb-4 d-flex justify-content-between"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Payment Confirmation")]),_c('b-link',{staticClass:"text-muted",on:{"click":_vm.cancel}},[_c('fa-icon',{attrs:{"icon":"times","size":"lg"}})],1)],1),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('validation-provider',{attrs:{"name":"Payment Amount","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment Amount","invalid-feedback":errors[0]}},[_c('b-input-group',{attrs:{"prepend":"Rp"}},[_c('money',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"0"},model:{value:(_vm.paymentAmount),callback:function ($$v) {_vm.paymentAmount=$$v},expression:"paymentAmount"}})],1)],1)]}}])}),_c('validation-provider',{attrs:{"name":"Bank Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bank Name *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"options":_vm.bankOption,"placeholder":"Select Bank Name","reduce":function (ref) {
	var id = ref.id;

	return id;
}},model:{value:(_vm.form.bankId),callback:function ($$v) {_vm.$set(_vm.form, "bankId", $$v)},expression:"form.bankId"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Account Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Account Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter account name"},model:{value:(_vm.form.accountName),callback:function ($$v) {_vm.$set(_vm.form, "accountName", $$v)},expression:"form.accountName"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Payment Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payment Date *","invalid-feedback":errors[0]}},[_c('b-form-datepicker',{class:{ 'is-invalid': errors.length },model:{value:(_vm.form.paymentDate),callback:function ($$v) {_vm.$set(_vm.form, "paymentDate", $$v)},expression:"form.paymentDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remark","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter remark","rows":"4","max-rows":"6"},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}})],1)]}}])})],1),_c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"warning","block":""},on:{"click":_vm.submit}},[_vm._v(" Save "),(_vm.isBusy)?_c('b-spinner',{staticClass:"ml-1",attrs:{"label":"Spinning","small":""}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }