<template>
  <b-modal
    id="modal-view-invoice"
    hide-header
    hide-footer
    centered
    size="xl"
  >
      <div class="mb-2 d-flex justify-content-between">
      <h5 class="font-weight-bold">View Detail Invoice [ {{ detail.invoiceNo}} ]</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <div class="p-3">
      <div class="d-flex justify-content-between">
        <label class="font-weight-bold"> Company Detail </label>
        <div>
          <b-link
            class="download"
            @click="fetchDownload('PDF')"
          >
            Download as .pdf
            <img
              src="@/assets/fi_download_blue.png"
              height="15"
            >
          </b-link>
          <b-link
            class="download ml-3"
            @click="fetchDownload('WORD')"
          >
            Download as .doc
            <img
              src="@/assets/fi_download_blue.png"
              height="15"
            >
          </b-link>
        </div>
      </div>
      <div class="mb-3">
          <b-row>
            <b-col>
                <b-form-group label="Company Name" >
                  <label class="font-weight-bold"> {{ detail.projectDetail.client ? detail.projectDetail.client.name : '-'}} </label>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Company Code" >
                <label class="font-weight-bold">{{ detail.projectDetail.client ? detail.projectDetail.client.code : '-' }}</label>
              </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Project Name" >
              <div>
               <label class="font-weight-bold"> {{ detail.projectDetail.name }} </label>
              </div>
                </b-form-group>
                </b-col>
                      <b-col>
                        <div class="text-muted">Project Code</div>
                        <div>
                       <label class="font-weight-bold"> {{ detail.projectDetail.code }} </label>
              </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group label="Billing Address" >
                        <label class="font-weight-bold">{{ detail.billingAddress }}</label>
                        </b-form-group>
                      </b-col>
                       <b-col>
                        <b-form-group label="Company Name" >
                        <label class="font-weight-bold">{{ detail.companyName }}</label>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <label class="font-weight-bold"> Invoice Info </label>

                    <b-row>
                     <b-col>
                        <div class="text-muted">Status Invoice</div>
                        <div>
                        <b-badge class="px-3 py-2">
                        {{ detail.status }}
                </b-badge>
              </div>
                      </b-col>
                      <b-col>
                        <b-form-checkbox
                        v-model="detail.isPaymentTerm"
                        disabled
                        > Build with payment term
                        </b-form-checkbox>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <div class="text-muted">Invoice Date</div>
                        <label class="font-weight-bold">{{ detail.invoiceDate ? moment(detail.invoiceDate).format('DD MMM YYYY') : '-' }}</label>
                </b-col>
                <b-col v-if="detail.isPaymentTerm">
                      <div class="text-muted">Terms</div>
                        <label class="font-weight-bold">{{ detail.paymentTerm ? detail.paymentTerm.title : '' }}</label>
                </b-col>
            </b-row>
                    <b-row>
                      <b-col>
                        <div class="text-muted">Reminder Date</div>
                        <label class="font-weight-bold">{{ detail.reminderDate ? moment(detail.reminderDate).format('DD MMM YYYY') : '-' }}</label>
                </b-col>
                <b-col>
                      <div class="text-muted">Overdue</div>
                        <label class="font-weight-bold">{{ detail.overdueLength }} {{ detail.overdueUnit }}</label>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="text-muted">Additional Note</div>
                    <label class="font-weight-bold">{{ detail.addtitionalNote }}</label>
                </b-col>
            </b-row>
            <div class="d-flex align-items-center justify-content-between">
        <h6 class="font-weight-bold">Invoice Detail</h6>
      </div>
      <b-table
        class="mt-2"
        striped
        hover
        :items="detail.invoiceDetails"
        :fields="fields"
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(price)="{ item }">
          {{ formatMoney(item.price, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
        <template #cell(discount)="{ item }">
          {{ formatMoney(item.discount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
        <template #cell(amount)="{ item }">
          {{ formatMoney(item.price * item.discount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </template>
      </b-table>
      <div class="mt-2 d-flex justify-content-end">
        Sub Total
        <span class="ml-5">
          {{ formatMoney(detail.subtotal, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
      <div class="mt-2 d-flex justify-content-end">
        Discount
        <span class="ml-5">
          {{ formatMoney(detail.discount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
      <div class="mt-2 d-flex justify-content-end">
        Discount Tambahan
        <span class="ml-5">
          {{ formatMoney(detail.additionalDiscount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
        <div class="mt-2 d-flex justify-content-end">
        PPN {{ detail.ppnTaxRate }}%
        <span class="ml-5">
          {{ formatMoney(ppnTaxAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
      <div class="mt-2 d-flex justify-content-end">
        PPh 23 ({{ detail.pphTaxRate }}%)
        <span class="ml-5">
          {{ formatMoney(pphTaxAmount, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
      <div class="mt-2 d-flex justify-content-end font-weight-bold">
        TOTAL
        <span class="ml-5 font-weight-bold">
          {{ formatMoney(total, { symbol: 'Rp.', precision: 0, thousand: '.' }) }}
        </span>
      </div>
    </div>
    </div>
      <b-row class="d-flex mt-4 mb-1">
      <b-col>
        <b-button
        v-if="detail.status === 'DRAFT'"
          class="cancel"
          variant="secondary"
          block
          @click="editInvoice"
        >
          Edit Invoice
        </b-button>
      </b-col>
      <b-col>
        <b-button
        v-if="detail.status === 'DRAFT' || detail.status === 'UNPAID'"
          class="next"
          variant="warning"
          block
          @click="setPaid"
        >
          Set Paid
        </b-button>
      </b-col>
       <b-col>
        <b-button
        v-if="detail.status === 'DRAFT'"
          class="next"
          variant="warning"
          block
          @click="sentInvoice"
        >
          Send Invoice
        </b-button>
      </b-col>
    </b-row>
  <modal-new-invoice />
  <modal-sent-invoice :invoiceId="invoiceId" @submit="fetchDetail" @refresh="refresh"/>
  <modal-payment-confirmation :invoiceId="invoiceId" :paymentAmount="total" @submit="submitPayment" @refresh="refresh"/>
  </b-modal>
</template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import moment from 'moment'
import modalNewInvoice from './ModalNewInvoice.vue'
import modalSentInvoice from './ModalSentInvoice.vue'
import modalPaymentConfirmation from './ModalPaymentConfirmation.vue'

export default {
  components: {
    modalNewInvoice,
    modalSentInvoice,
    modalPaymentConfirmation
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    clientName: null,
    technology: null,
    code: null,
    status: null,
    invoiceId: null,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'description', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'quantity', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'price', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'discount', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'amount', tdClass: 'text-center', thClass: 'text-center' }
    ]
  }),

  computed: {
    total() {
      var total = this.detail.subtotal - this.detail.discountTotal - this.detail.additionalDiscount + this.pphTaxAmount + this.ppnTaxAmount
      return total
    },
    pphTaxAmount() {
      return this.detail.subtotal * this.detail.pphTaxRate / 100
    },
    ppnTaxAmount() {
      return this.detail.ppnTaxRate * this.detail.subtotal / 100
    }
  },

  methods: {
    formatMoney,
    editInvoice() {
      this.$bvModal.show('modal-new-invoice')
    },
    sentInvoice() {
      this.invoiceId = this.detail.id
      this.$bvModal.show('modal-sent-invoice')
    },
    setPaid() {
      this.invoiceId = this.detail.id
      this.$bvModal.show('modal-payment-confirmation')
    },
    async fetchDownload(type) {
      const response = type === 'PDF' ? await api.invoice.downloadPdf(this.detail.id) : await api.invoice.downloadWord(this.detail.id)
      const url = URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      )
      const link = document.createElement('a')
      const time = moment().format('DD-MM-YYYY HH mm ss')
      link.href = url
      link.setAttribute('download', `${this.detail.invoiceNo}_${time}`)
      document.body.appendChild(link)
      link.click()
    },
    async fetchDetail() {
      const { data } = await api.invoice.detail(this.invoiceId) || []
      this.detail.status = data.status
    },
    refresh() {
      this.$emit('refresh')
    },
    submitPayment() {
      this.fetchDetail()
    },
    cancel() {
      this.$bvModal.hide('modal-view-invoice')
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  background: #F6F4F4;
  color: #000;
}

.status {
  background: rgba(214, 35, 174, 0.2);;
  color: #D623AE;
}

.download {
  color: #0057FF;
}
</style>
