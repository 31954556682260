<template>
  <b-modal
    id="modal-payment-confirmation"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="mb-4 d-flex justify-content-between">
      <h5 class="font-weight-bold">Payment Confirmation</h5>
      <b-link
        class="text-muted"
        @click="cancel"
      >
        <fa-icon icon="times" size="lg" />
      </b-link>
    </div>
    <validation-observer
      ref="form"
      slim
    >
      <validation-provider
        name="Payment Amount"
        rules="required|min_value:0"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Payment Amount"
          :invalid-feedback="errors[0]"
        >
          <b-input-group
            prepend="Rp"
          >
            <money
              class="form-control"
              :class="{ 'is-invalid': errors.length }"
              v-model="paymentAmount"
              placeholder="0"
            />
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Bank Name"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Bank Name *"
          :invalid-feedback="errors[0]"
        >
          <custom-select
            v-model="form.bankId"
            label="name"
            :clearable="false"
            :options="bankOption"
            placeholder="Select Bank Name"
            :reduce="({ id }) => id"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Account Name"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Account Name *"
          :invalid-feedback="errors[0]"
        >
          <b-form-input
            v-model="form.accountName"
            placeholder="Enter account name"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Payment Date"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Payment Date *"
          :invalid-feedback="errors[0]"
        >
          <b-form-datepicker
            v-model="form.paymentDate"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        name="Remark"
        rules="required"
        v-slot="{ errors }"
      >
        <b-form-group
          label="Remark"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-model="form.remarks"
            placeholder="Enter remark"
            rows="4"
            max-rows="6"
            :class="{ 'is-invalid': errors.length }"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <div class="mt-3 d-flex justify-content-center">
      <b-button
        class="text-white"
        variant="warning"
        block
        @click="submit"
      >
        Save
        <b-spinner
          v-if="isBusy"
          class="ml-1"
          label="Spinning"
          small
        />
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    invoiceId: {
      type: String,
      default: () => ''
    },
    paymentAmount: {
      type: Number,
      default: () => 0
    }
  },
  created() {
    this.fetchBanks()
  },
  data: () => ({
    form: {
      paymentAmount: 0,
      bankId: null,
      accountName: null,
      paymentDate: null,
      remarks: null
    },
    bankOption: [],
    isBusy: false
  }),

  methods: {
    async fetchBanks() {
      const { data } = await api.bank.list()
      this.bankOption = data.data
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      this.isBusy = true
      try {
        this.form.invoiceId = this.invoiceId
        this.form.paymentAmount = this.paymentAmount
        await api.invoice.setPaid(this.form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success Paid Invoice', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$bvModal.hide('modal-payment-confirmation')
        setTimeout(() => {
        }, 2000)
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
      this.$emit('submit', this.form)
      this.$emit('refresh')
    },
    resetModal() {
    },
    cancel() {
      this.$bvModal.hide('modal-payment-confirmation')
    }
  }
}
</script>
